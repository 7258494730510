import { Data, Layout } from 'plotly.js'
import { HCPDemographicsType } from '../../api/hcp-demographics'

export type DateRangeParam = '1m' | '3m' | '6m' | '1y' | 'all'

export type PlotlyResponse = { data: Data[]; layout: Partial<Layout> }

export interface GenerateChartState {
    data?: PlotlyResponse
    isLoading: boolean
    lastOrgId?: string
    lastDateRange?: DateRangeParam
}

export interface GenerateDatelessChartState extends Omit<GenerateChartState, 'lastDateRange'> {}

export interface PatientsExperiencingRelapseRateState extends GenerateChartState {}

export interface AveragePROScoresState extends GenerateChartState {
    lastPro?: string
}

export interface EnrollmentChartState extends GenerateChartState {}

export type RelapseHistoryAndRateData = {
    ORGANIZATION_ID: { [key: string]: string }
    MEAN_RELAPSE_RATE_AT_BASELINE: { [key: string]: number }
    MEAN_RELAPSE_RATE_AT_12_MONTHS: { [key: string]: number }
    MEAN_NUM_OF_RELAPSE_FREE_DAYS: { [key: string]: number }
    MEAN_NUM_OF_RELAPSE_FREE_DAYS_12_MONTHS: { [key: string]: number }
}

export interface RelapseHistoryAndRateState {
    data?: RelapseHistoryAndRateData
    isLoading: boolean
    lastOrgId?: string
}

export interface PatientsTreatedWithDmtState extends GenerateChartState {}

export interface PatientsExperiencingFallsState extends GenerateChartState {}

export interface DMTBreakdownByRouteState extends GenerateDatelessChartState {}

export interface DMTBreakdownByMedicationState extends GenerateDatelessChartState {}

export interface EmploymentAndInsuranceState {
    insurance?: PlotlyResponse
    employment?: PlotlyResponse
    isLoading: boolean
    lastOrgId?: string
}

export interface ClinicalAssessmentState extends Omit<GenerateChartState, 'data' | 'lastDateRange'> {
    meanChange?: PlotlyResponse
    patientPecent?: PlotlyResponse
    lastAssessment?: string
}

export interface HospitalizationsRateState extends GenerateChartState {}

export interface ERUtilizationsRateState extends GenerateChartState {}

export interface RaceState extends GenerateDatelessChartState {}

export interface EthnicityState extends GenerateDatelessChartState {}

export interface DemographicsOverTimeState extends GenerateChartState {
    lastDemographic?: HCPDemographicsType
}

export type PatientBaselineDemographicsData = {
    my_org_mean_age: { mean: number; std_dev: number }
    other_org_mean_age: { mean: number; std_dev: number }
    sex_pies: PlotlyResponse
    ms_subtype_pies: PlotlyResponse
    age_breakdown: PlotlyResponse
}

export interface PatientBaselineDemographicsState extends Omit<GenerateChartState, 'lastDateRange' | 'data'> {
    data?: PatientBaselineDemographicsData
}

export type MSDurationData = {
    my_org_mean_duration: { mean: number; std_dev: number }
    all_orgs_mean_duration: { mean: number; std_dev: number }
    plots: PlotlyResponse
}

export interface MSDurationState extends Omit<GenerateChartState, 'lastDateRange' | 'data'> {
    data?: MSDurationData
}

export const initialPatientsExperiencingRelapseState: PatientsExperiencingRelapseRateState = {
    isLoading: true
}

export const initialAveragePROScoresState: AveragePROScoresState = {
    isLoading: true
}

export const initialEnrollmentChartState: EnrollmentChartState = {
    isLoading: true
}

export const initialRelapseHistoryAndState: RelapseHistoryAndRateState = {
    isLoading: true
}

export const initialPatientsTreatedWithDmtState: PatientsTreatedWithDmtState = {
    isLoading: true
}

export const initialPatientsExperiencingFallsState: PatientsExperiencingFallsState = {
    isLoading: true
}

export const initialDMTBreakdownByRouteState: DMTBreakdownByRouteState = {
    isLoading: true
}

export const initialDMTBreakdownByMedicationState: DMTBreakdownByMedicationState = {
    isLoading: true
}

export const initialEmploymentAndInsuranceState: EmploymentAndInsuranceState = {
    isLoading: true
}

export const initialHospitalizationsRateState: HospitalizationsRateState = {
    isLoading: true
}

export const initialERUtilizationsRateState: ERUtilizationsRateState = {
    isLoading: true
}

export const initialRaceState: RaceState = {
    isLoading: true
}

export const initialEthnicityState: EthnicityState = {
    isLoading: true
}

export const initialPatientBaselineDemographicsState: PatientBaselineDemographicsState = {
    isLoading: true
}

export const initialMSDurationState: MSDurationState = {
    isLoading: true
}

export const initialDemographicsOverTimeState: DemographicsOverTimeState = {
    isLoading: true
}

export const initialClinicalOutcomesAssessmentState: ClinicalAssessmentState = {
    isLoading: true
}

export type Org = {
    name: string
    id: string
}

export interface OrganizationsState {
    orgs: Org[]
    selectedOrgId?: string
    isOrgsLoading?: boolean
}

export const initialOrganizationsState: OrganizationsState = {
    orgs: []
}

export type ChartQueryParams = {
    [key in ChartKeys]: BasicChartQueryObject | ExtendedChartQueryObject
}

export interface BasicChartQueryObject {
    dateRange: DateRangeParam
}

export interface ExtendedChartQueryObject extends BasicChartQueryObject {
    [key: string]: string
}

export enum ChartKeys {
    enrollment_chart = 'enrollment_chart',
    patients_experiencing_relapse_chart = 'patients_experiencing_relapse_chart',
    patients_treated_with_dmt_rate_chart = 'patients_treated_with_dmt_rate_chart',
    patients_experiencing_falls_rate_chart = 'patients_experiencing_falls_rate_chart',
    average_pro_scores = 'average_pro_scores',
    dmt_breakdown_by_route = 'dmt_breakdown_by_route',
    dmt_breakdown_by_medication = 'dmt_breakdown_by_medication',
    relapse_history_and_rate = 'relapse_history_and_rate',
    emergency_urgentcare_visit_rate = 'emergency_urgentcare_visit_rate',
    hospitalization_rate = 'hospitalization_rate',
    clinical_outcomes_patient_percentage = 'clinical_outcomes_patient_percentage',
    patient_demographics_at_baseline = 'patient_demographics_at_baseline',
    patient_demographics_ethnicity = 'patient_demographics_ethnicity',
    patient_demographics_race = 'patient_demographics_race',
    patient_demographics_employment_and_insurance = 'patient_demographics_employment_and_insurance',
    patient_demographics_ms_duration = 'patient_demographics_ms_duration',
    pt_demo_mean_age_over_time = 'pt_demo_mean_age_over_time',
    pt_demo_percent_female_over_time = 'pt_demo_percent_female_over_time',
    pt_demo_percent_non_white_over_time = 'pt_demo_percent_non_white_over_time',
    pt_demo_percent_relapse_remitting_over_time = 'pt_demo_percent_relapse_remitting_over_time',
    pt_demo_percent_commercial_insurance_over_time = 'pt_demo_percent_commercial_insurance_over_time',
    pt_demo_percent_receiving_mri_annually = 'pt_demo_percent_receiving_mri_annually',
    clinical_outcomes_mean_change = 'clinical_outcomes_mean_change',
    clinical_outcomes_assessment = 'clinical_outcomes_assessment'
}
