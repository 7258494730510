/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Trans } from '@lingui/macro'
import { Alert, AlertTitle, CircularProgress, styled } from '@mui/material'
import { Box } from '@mui/system'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { FunctionComponent, useEffect, useState } from 'react'
import { OrganizationsState, RelapseHistoryAndRateState, siteLevelBenchmarkingActions } from '../state'
import { SiteLegendComponent } from './SiteLegendComponent'

export type RelapseHistoryAndRateComponentProps = RoutedFrameworkComponentProps<
    {},
    {},
    RelapseHistoryAndRateState & OrganizationsState,
    typeof siteLevelBenchmarkingActions,
    {}
>

export const RelapseHistoryAndRateComponent: FunctionComponent<RelapseHistoryAndRateComponentProps> = ({ state, routing, actions }) => {
    const [orgIdx, setOrgIdx] = useState('')
    const [allOrgIdx, setAllOrgIdx] = useState('')

    useEffect(() => {
        actions.getRelapseHistoryAndRate({ query: routing.queryParams, path: routing.pathParams })
    }, [state.selectedOrgId, routing.queryParams, routing.pathParams, actions])

    let orgId = routing.pathParams['orgId']

    useEffect(() => {
        if (state.data?.ORGANIZATION_ID) {
            // @ts-ignore
            for (const key of Object.keys(state.data?.ORGANIZATION_ID)) {
                if (state.data?.ORGANIZATION_ID[key] === orgId) {
                    setOrgIdx(key)
                } else if (state.data?.ORGANIZATION_ID[key] === 'All Organizations') {
                    setAllOrgIdx(key)
                }
            }
        }
    }, [state.data, state.selectedOrgId, orgId, routing.pathParams])

    const SyledLabelBox = styled(Box)`
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #4c4c4c;
        font-family: Arial;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
    `

    const baseLineLabel = () => (
        <Box>
            <SyledLabelBox>
                <Trans id={'baseLineLabel3'}>Annualized relapse rate during baseline period</Trans>
            </SyledLabelBox>
        </Box>
    )

    const twelveMonthsLabel = () => (
        <Box>
            <SyledLabelBox>
                <Trans id={'twelveMonthsLabel3'}>Annualized relapse rate at 12 months</Trans>
            </SyledLabelBox>
        </Box>
    )

    const relapseFreeLabel = () => (
        <Box>
            <SyledLabelBox>
                <Trans id={'relapseFreeLabel1'}>Mean # of relapse-free days during the 6-month baseline period</Trans>
            </SyledLabelBox>
        </Box>
    )

    const relapseFreeTwelveMonthsFollowUpLabel = () => (
        <Box>
            <SyledLabelBox>
                <Trans id={'relapseFreeTwelveMonthsFollowUpLabel1'}>Mean # of relapse-free days during the 12 month follow-up period</Trans>
            </SyledLabelBox>
        </Box>
    )

    const SyledDataPointBox = styled(Box)`
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
    `

    const SyledMyOrgDataPoint = styled(Box)`
        height: 41px;
        color: #8bb8e8;
        font-family: Arial;
        font-size: 36px;
        letter-spacing: 0;
        line-height: 42px;
        text-align: center;
        margin-bottom: 20px;
    `

    const SyledAllOrgsDataPoint = styled(Box)`
        height: 41px;
        color: #aaaeb0;
        font-family: Arial;
        font-size: 36px;
        letter-spacing: 0;
        line-height: 42px;
        text-align: center;
        margin-bottom: 20px;
    `

    const SyledMyOrgNoDataPoint = styled(SyledMyOrgDataPoint)`
        font-size: 18px;
        color: #5e2120;
        background-color: #fdeded;
        max-width: 240px;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
    `

    const SyledAllOrgsNoDataPoint = styled(SyledAllOrgsDataPoint)`
        font-size: 18px;
        color: #5e2120;
        background-color: #fdeded;
        max-width: 240px;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
    `

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                marginBottom: '20px',
                padding: '10px',
                transition: 'opacity 2s ease'
            }}
        >
            <h3>
                <Trans id='Relapse History and Rate'>Relapse History and Rate</Trans>
            </h3>
            {state.data !== undefined && (
                <>
                    <SiteLegendComponent />
                    <Box width='100%' display='flex' flexDirection='row'>
                        <Box display='flex' flexDirection='row' width='100%' justifyContent='space-around' alignItems='baseline'>
                            <SyledDataPointBox>
                                {state.data?.MEAN_RELAPSE_RATE_AT_BASELINE[orgIdx] ? (
                                    <SyledMyOrgDataPoint>{state.data?.MEAN_RELAPSE_RATE_AT_BASELINE[orgIdx].toFixed(2)}%</SyledMyOrgDataPoint>
                                ) : (
                                    <SyledMyOrgNoDataPoint>
                                        <Trans>Insufficient Data</Trans>
                                    </SyledMyOrgNoDataPoint>
                                )}
                                {baseLineLabel()}
                            </SyledDataPointBox>
                            <SyledDataPointBox>
                                {state.data?.MEAN_RELAPSE_RATE_AT_12_MONTHS[orgIdx] ? (
                                    <SyledMyOrgDataPoint>{state.data?.MEAN_RELAPSE_RATE_AT_12_MONTHS[orgIdx].toFixed(2)}%</SyledMyOrgDataPoint>
                                ) : (
                                    <SyledMyOrgNoDataPoint>
                                        <Trans>Insufficient Data</Trans>
                                    </SyledMyOrgNoDataPoint>
                                )}
                                {twelveMonthsLabel()}
                            </SyledDataPointBox>
                            <SyledDataPointBox>
                                {state.data?.MEAN_NUM_OF_RELAPSE_FREE_DAYS[orgIdx] ? (
                                    <SyledMyOrgDataPoint>{state.data?.MEAN_NUM_OF_RELAPSE_FREE_DAYS[orgIdx].toFixed(2)}</SyledMyOrgDataPoint>
                                ) : (
                                    <SyledMyOrgNoDataPoint>
                                        <Trans>Insufficient Data</Trans>
                                    </SyledMyOrgNoDataPoint>
                                )}
                                {relapseFreeLabel()}
                            </SyledDataPointBox>
                            <SyledDataPointBox>
                                {state.data?.MEAN_NUM_OF_RELAPSE_FREE_DAYS_12_MONTHS[orgIdx] ? (
                                    <SyledMyOrgDataPoint>
                                        {state.data?.MEAN_NUM_OF_RELAPSE_FREE_DAYS_12_MONTHS[orgIdx].toFixed(2)}
                                    </SyledMyOrgDataPoint>
                                ) : (
                                    <SyledMyOrgNoDataPoint>
                                        <Trans>Insufficient Data</Trans>
                                    </SyledMyOrgNoDataPoint>
                                )}
                                {relapseFreeTwelveMonthsFollowUpLabel()}
                            </SyledDataPointBox>
                        </Box>
                    </Box>
                    <Box width='100%' display='flex' flexDirection='row' marginBottom='20px'>
                        <Box display='flex' flexDirection='row' width='100%' justifyContent='space-around' alignItems='baseline'>
                            <SyledDataPointBox>
                                {state.data?.MEAN_RELAPSE_RATE_AT_BASELINE[allOrgIdx] ? (
                                    <SyledAllOrgsDataPoint>{state.data?.MEAN_RELAPSE_RATE_AT_BASELINE[allOrgIdx].toFixed(2)}%</SyledAllOrgsDataPoint>
                                ) : (
                                    <SyledAllOrgsNoDataPoint>
                                        <Trans>Insufficient Data</Trans>
                                    </SyledAllOrgsNoDataPoint>
                                )}
                                {baseLineLabel()}
                            </SyledDataPointBox>
                            <SyledDataPointBox>
                                {state.data?.MEAN_RELAPSE_RATE_AT_12_MONTHS[allOrgIdx] ? (
                                    <SyledAllOrgsDataPoint>{state.data?.MEAN_RELAPSE_RATE_AT_12_MONTHS[allOrgIdx].toFixed(2)}%</SyledAllOrgsDataPoint>
                                ) : (
                                    <SyledAllOrgsNoDataPoint>
                                        <Trans>Insufficient Data</Trans>
                                    </SyledAllOrgsNoDataPoint>
                                )}
                                {twelveMonthsLabel()}
                            </SyledDataPointBox>
                            <SyledDataPointBox>
                                {state.data?.MEAN_NUM_OF_RELAPSE_FREE_DAYS[allOrgIdx] ? (
                                    <SyledAllOrgsDataPoint>{state.data?.MEAN_NUM_OF_RELAPSE_FREE_DAYS[allOrgIdx].toFixed(2)}</SyledAllOrgsDataPoint>
                                ) : (
                                    <SyledAllOrgsNoDataPoint>
                                        <Trans>Insufficient Data</Trans>
                                    </SyledAllOrgsNoDataPoint>
                                )}
                                {relapseFreeLabel()}
                            </SyledDataPointBox>
                            <SyledDataPointBox>
                                {state.data?.MEAN_NUM_OF_RELAPSE_FREE_DAYS_12_MONTHS[allOrgIdx] ? (
                                    <SyledAllOrgsDataPoint>
                                        {state.data?.MEAN_NUM_OF_RELAPSE_FREE_DAYS_12_MONTHS[allOrgIdx].toFixed(2)}
                                    </SyledAllOrgsDataPoint>
                                ) : (
                                    <SyledAllOrgsNoDataPoint>
                                        <Trans>Insufficient Data</Trans>
                                    </SyledAllOrgsNoDataPoint>
                                )}
                                {relapseFreeTwelveMonthsFollowUpLabel()}
                            </SyledDataPointBox>
                        </Box>
                    </Box>
                </>
            )}
            {state.data === undefined && (
                <Box display='flex' justifyContent='center' alignItems='center' height='50vh'>
                    {state.isLoading && <CircularProgress />}
                    {!state.isLoading && (
                        <Alert severity='error' sx={{ width: '100%' }}>
                            <AlertTitle>Error</AlertTitle>
                            Error loading Relapse History and Rate Data
                        </Alert>
                    )}
                </Box>
            )}
        </Box>
    )
}
